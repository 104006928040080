import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import StorageHelper from "../helpers/storage.helper";
import { WINDOW } from "../providers/window.provider";

@Injectable({
    providedIn: 'root'
  })
export class DarkModeService {
    private isDarkMode: boolean = false;
    private readonly localStorageKey = 'AxedrasGBI.dark-mode';
    private readonly darkModeCssClassName = 'dark-mode';

    constructor(@Inject(DOCUMENT) private readonly document: Document,
        @Inject(WINDOW) private readonly window: Window) {
    }

    public initialize(): void {
        const prefersDarkMode = this.window.matchMedia("(prefers-color-scheme: dark)").matches;
        const savedDarkMode = StorageHelper.getItemAsObject<boolean>(localStorage, this.localStorageKey);

        if(savedDarkMode !== this.isDarkMode) {
            this.toggle();
        }
        else if (savedDarkMode == null && prefersDarkMode !== this.isDarkMode) {
            this.toggle();
        }
    }

    public toggle() : void {
        this.isDarkMode = this.document.body.classList.toggle(this.darkModeCssClassName);
        StorageHelper.setItemFromObject<boolean>(localStorage, this.localStorageKey, this.isDarkMode);
    }

    public getIsDarkMode(): boolean {
        return this.isDarkMode;
    }
}